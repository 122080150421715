@import '../../../styles/main.scss';

.container{
    display: flex;
    flex-direction: column ;
    align-items: center;
    @include bg-mobile-header;

    .top-part-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px 15px 0 15px;
        //hauteur du menu en mobile
        margin-top: 55px;

        img {
            margin-bottom: 20px;
        }

        .headerTitle {
            text-align: center;
            margin-bottom: 20px;

            .mainTitleWrapper {
                margin-bottom: 10px;
            }
        }

        .headerContact {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            margin-bottom: 20px;

            a {
                display: flex;
                align-items: center;
                justify-content: space-around;
                border: 2px solid;
                border-radius: 4px;
                @include border-primary;
                padding: 10px 20px;
                margin: 5px 0;
                width: fit-content;
            }

            .cellIcon {
                display: inline-block;
                font-family: GlyphFont;
                font-size: 80px;
                color: #5f9dcc;
                text-align: center;
                margin-top: -40px;
            }

            .emailIcon {
                display: inline-block;
                font-family: GlyphFont;
                font-size: 80px;
                color: #5f9dcc;
                text-align: center;
                margin-top: -40px;
            }

            .textWrapper {
                margin-left: 10px;
            }
        }
    }

    .nav-bar {
        @include bg-primary;
        display: flex;                
        position: fixed;
        padding: 15px 0;
        height: 25px;
        width: 100%;
        order: -1;
        z-index: 99;

        .hamburgerMenu-icon-container {
            display: flex;
            padding: 0 15px;
        }

        ul {
            display: flex;
            flex-direction: column;
            width: 100%;
            height: calc(100vh - 55px);
            position: absolute;
            top: 55px;
            left: -100%;
            background: rgba(0, 0, 0, 0);
            transition: all 0.5s ease;            

            &.menu-opened {                
                background: rgba(0, 0, 0, 0.85);
                left: 0;
                transition: all 0.5s ease;
                z-index: 10;
                text-align: center;                
            }

            li {
                margin: 10px;
                font-size: 3rem;
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .container{
        width: 760px;
        margin: auto;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        background: none;        

        .top-part-header {
            flex-direction: row;
            justify-content: space-around;
            width: 100%;
            margin-top: 0;

            .logo {
                flex: 0 0 25%
            }

            .headerTitle {
                margin: 0 10px;
                flex: 0 0 50%
            }

            .headerContact {
                align-items: flex-end;
                flex: 0 0 25%;

                a {
                    border: none;
                    padding: 0;
                    margin: 0;
                }

                .cellIcon {
                    display: none;
                }

                .emailIcon {
                    display: none;
                }

                .textWrapper{
                    margin: 0;
                }
            }
        }

        .nav-bar {
            order: 2;            
            width: calc(100vw);            
            margin-left: calc(-50vw + 50%);
            margin-right: calc(-50vw + 50%);
            position: relative;

            .hamburgerMenu-icon-container {
                display: none;
            }

            ul {
                width: 760px;
                margin: 0 auto;
                flex-direction: row;
                justify-content: space-around;
                height: 100%;
                position: relative;
                top: inherit;
                left: inherit;
                background: none;

                &.menu-opened {
                    top: initial;
                    left: initial;
                    background-color: initial;
                    transition: 0s;                    
                }

                li {
                    margin: 0;
                    font-size: 1.8rem;
                }
            }
        }
    }
}

@media (min-width: $screen-md-min){
    .container{
        width: 960px;

        .nav-bar{
            
            ul {
                width: 960px;                
            }
        }        
    }
}