@import '../../styles/main';

.sectionContainer{    
    margin: 10px auto;
    width: 95%;    

    .titleWrapper{
        padding: 5px 0;    
        text-align: center;        
        @include bg-primary;
    }

    .content{
        background-color: rgba(0,0,0,0.7);        
        font-size: 1.4rem;
    }
}

@media (min-width: $screen-sm-min) {
    .sectionContainer{        
        width: 80%;
        margin: 20px auto;
    }
}

@media (min-width: $screen-md-min){
    .sectionContainer{
        width: 960px;
    }
}