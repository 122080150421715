@import url('https://fonts.googleapis.com/css?family=Open+Sans:700,600');

.content {
    padding: 20px;
}

.firstParagraphWrapper{
    margin-bottom: 50px;
}

.facebookParagraphWrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;

    span {
        margin-left: 10px;
    }
}

.contactComponentWrapper{
    margin-bottom: 25px;
}