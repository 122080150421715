.glyphAndTextContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    margin-bottom: 10px;
    margin-right: 20px;
}

.glyphWrapper {
    min-width: 60px;
    text-align: center;
}

.textWrapper{
    margin-left: 25px;
}