@import "../../styles/main";

.carousel {
    position: relative;    
    margin: 20px auto;
}

.carousel-container {    
    position: absolute;
    overflow: hidden;
}

.left-arrow-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: -1.75rem;
    top: calc(50% - 1.75rem);
    z-index: 2;

    width: 3.5rem;
    height: 3.5rem;
    border: 0.375rem solid #fff;
    border-radius: 50%;
    background: white;
    opacity: 0;

    &::after {
        content: "";
        position: absolute;
        left: 8px;
        width: 1rem;
        height: 1rem;
        border-top: 0.5rem solid #000;
        border-right: 0.5rem solid #000;
        -moz-transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
        -ms-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
    }
}

.right-arrow-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -1.75rem;
    top: calc(50% - 1.75rem);
    z-index: 2;

    width: 3.5rem;
    height: 3.5rem;
    border: 0.375rem solid #fff;
    border-radius: 50%;
    background: white;
    opacity: 0;

    &::after {
        content: "";
        position: absolute;
        right: 8px;
        width: 1rem;
        height: 1rem;
        border-top: 0.5rem solid #000;
        border-right: 0.5rem solid #000;
        -moz-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
    }
}

.slider {
    position: absolute;
    top: 0;
    left: 0;    
    display: flex;
    list-style: none;    
}

.carousel-navigator {
    position: absolute;
    width: 100%;
    bottom: 20px;
    display: flex;
    justify-content: center;
    height: 20px;
    overflow: hidden;
    z-index: 2;
    opacity: 0;

    li {
        padding: 0 5px;
    }

    li.slide-selected {
        margin-top: -20px;
    }
}

.show-control {
    opacity: 1;
}