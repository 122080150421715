.contactPersonsContainer2Column {
    display: flex;
    flex-direction: row;    
    flex-wrap: wrap;    
    flex-basis: 100%;
    gap: 2rem;
}

.contactPersonContainer {    
    flex-basis: 50%;    
}