@import "../../../../styles/main.scss";

.content {
    padding: 20px;
    text-align: justify;
}

.realisations-container {
    display: flex;
    flex-direction: column;

    // cible les div qui ont la classe realisations-content sauf le premier
    div:not(:first-child).realisations-content {
        margin-top: 15px;
    }

    .realisations-content {
        display: flex;
        flex-direction: column;

        .item {
            flex: 1 1 1;
        }

        .mobile-order1 {
            order: 1;
        }

        .mobile-order2 {
            order: 2;
        }
    }
}

@media (min-width: $screen-sm-min) {
}

@media (min-width: $screen-md-min) {
    .realisations-container {        
        .realisations-content {
            flex-direction: row;

            .item {
                flex: 1 0 50%;
                width: 400px;

                // div {
                //     margin: 0 10px;
                // }
            }

            .mobile-order1 {
                order: 2;
            }

            .mobile-order2 {
                order: 1;
            }
        }
    }
}
