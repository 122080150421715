@import url('https://fonts.googleapis.com/css?family=Open+Sans:700,600');

$font-color-primary: #fff;

$screen-lg-min: 1200px;

$screen-md-min: 992px;
$screen-md-max: ($screen-lg-min -1);

$screen-sm-min: 768px;
$screen-sm-max: ($screen-md-min -1);

$screen-xs-min: 480px;
$screen-xs-max: ($screen-sm-min -1);

$screen-xxs-min: 320px;
$screen-xxs-max: ($screen-xs-min -1);



@mixin bg-primary{
    // background: #97cf30;
    // background: -moz-linear-gradient(top,rgba(151,207,48,1) 0,rgba(100,163,72,1) 100%);
    // background: -webkit-gradient(linear,left top,left bottom,color-stop(0,rgba(151,207,48,1)),color-stop(100%,rgba(100,163,72,1)));
    // background: -webkit-linear-gradient(top,rgba(151,207,48,1) 0,rgba(100,163,72,1) 100%);
    // background: -o-linear-gradient(top,rgba(151,207,48,1) 0,rgba(100,163,72,1) 100%);
    // background: -ms-linear-gradient(top,rgba(151,207,48,1) 0,rgba(100,163,72,1) 100%);
    background: linear-gradient(to bottom,rgba(151,207,48,1) 0,rgba(100,163,72,1) 100%);
}

@mixin bg-mobile-header{
    // background: black;
    // background: -moz-linear-gradient(top,rgba(0,0,0,0.5) 0,rgba(0,0,0,0.5) 90%, rgba(0,0,0,0) 100%);
    // background: -webkit-gradient(linear,left top,left bottom,color-stop(0,rgba(0,0,0,0.5)),color-stop(90%,rgba(0,0,0,0.5)),color-stop(100%,rgba(0,0,0,0)));
    // background: -webkit-linear-gradient(top,rgba(0,0,0,0.5) 0,rgba(0,0,0,0.5) 90%,rgba(0,0,0,0) 100%);
    // background: -o-linear-gradient(top,rgba(0,0,0,0.5) 0,rgba(0,0,0,0.5) 90%,rgba(0,0,0,0) 100%);
    // background: -ms-linear-gradient(top,rgba(0,0,0,0.5) 0,rgba(0,0,0,0.5) 90%, rgba(0,0,0,0) 100%);
    background: linear-gradient(to bottom,rgba(0,0,0,0.5) 0,rgba(0,0,0,0.5) 90%, rgba(0,0,0,0) 100%);
}

@mixin border-primary{
    // border-color: #97cf30;
    // border-color: -moz-linear-gradient(top,rgba(151,207,48,1) 0,rgba(100,163,72,1) 100%);
    // border-color: -webkit-gradient(linear,left top,left bottom,color-stop(0,rgba(151,207,48,1)),color-stop(100%,rgba(100,163,72,1)));
    // border-color: -webkit-linear-gradient(top,rgba(151,207,48,1) 0,rgba(100,163,72,1) 100%);
    // border-color: -o-linear-gradient(top,rgba(151,207,48,1) 0,rgba(100,163,72,1) 100%);
    // border-color: -ms-linear-gradient(top,rgba(151,207,48,1) 0,rgba(100,163,72,1) 100%);
    border-color: linear-gradient(to bottom,rgba(151,207,48,1) 0,rgba(100,163,72,1) 100%);
}

//story book
.sb-show-main.sb-main-padded {
    padding: 0;
}

html {    
    background: #2e79a3 url(../../src/components/assets/background1.jpg);
    background-repeat: no-repeat;
    background-attachment: fixed;
    font-size: 10px;
}

body {
    overflow-x: hidden;    
}

header, body, div, ul, li, h1, h2, h3, h4, h5, h6, p, a {
    margin: 0;
    padding: 0;
    font-family: "Open sans",serif;
    color: $font-color-primary;
    text-decoration: none;
    list-style: none;
}

h1 {
    font-size: 3.0rem;
}

h2 {
    font-size: 2.2rem;
}

h3 {
    font-size: 1.8rem;
}

h4 {
    font-size: 1.2rem;
}

h5 {
    font-size: 1rem;
}

h6 {
    font-size: 0.8rem;
}

.underline {
    text-decoration: underline;
}

@media (min-width: $screen-md-min){
    h1 {
        font-size: 3.4rem;
    }

    h2 {
        font-size: 2.6rem;
    }

    h3 {
        font-size: 2.2rem;
    }

    h4 {
        font-size: 1.6rem;
    }

    h5 {
        font-size: 1.4rem;
    }

    h6 {
        font-size: 1.2rem;
    }
}