@import '../../../styles/main.scss';

.footer {
    position: absolute;
    clear: both;
    height: 26px;    
    font-size: 10px;
    @include bg-primary;    
    width: 100%;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}