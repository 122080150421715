.paragraphXs {
    font-size: 1.2rem;
}

.paragraphSm {
    font-size: 1.4rem;
}

.paragraphMd {
    font-size: 1.6rem;
}

.paragraphLg {
    font-size: 1.8rem;
}

.paragraphXl {
    font-size: 2rem;
}