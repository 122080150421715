.content {
    padding: 20px;
    text-align: justify;

    .list-level1 li {
        margin: 10px 0 10px 50px;

        list-style-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAUCAYAAACaq43EAAAFUUlEQVR4XpVVa4hdVxX+1lp7n3vvPDOpadKYR0lVKqn+SBofLTY+QislPyySWKUqoiRFf4TitCoULoMg2OKPkhbKiIhjrZKhtFrB+suWgkpJVSg0sTa2k4yhmaSZO7l37nnutRz2uRdCMBq/w+Ksfc4+6/s232Idwn+DgdrPHZnU0c7uYL071PJbwOWUZ7SYNOUqO5kov1Ul4xdURt9U6/5jfarLR+5+Icf/AF2N8PBvP715xCb3O0rvJE4/amobCU6YKjipIAQw2BiVQlAxtzoMPZEI/b0lm18O1Hux4KXs27f9+eI1ET/wxwdabmV5f14sfg1W3s5s40KBiDzYCEIKIoMIwCz1GrUQYYVQZcJjHSN7k0wWWMafCs3RV1aef9+5mZkZxQCMy9A+3h7Ri6cezMu3Hg1Kn1W4CYOQwQNAJKxBMFBcE6xeE0OJYBBSK6ZgusdQft7QeRLF6acn9r/yzceOH7rp2LEDAgAOAxz6zaGR7tK5r1qh34ClW0EMMAGw+rLIfIUlBiPDcBeMEL+LTxRGoKDlpkbQjVyt7oF/+94LNzbm5n5/3y8i8YE1FazL966m/e8FhC1MY3BUgUF1EatvMRnw01Vbhut9UVAAmSKQJ8BGXXnpNh+a709Hm5si8bbJHbd3+28cqay3RQXEZBADYDzkimTGCiONp6cY9clAGgkNDBse3AAxhldG4SsUQlCURJVM5Gkm0v5De93F4vx0lXXuIhV25uCNwcTxaxselxjKDkoCDGkgIDAoNhwGOcBEYDIQWRQKdiAKEB1VKyafDZ3NP3SLnZN35ejd09CUwIDGjQHKBhdLsREo2ukCBQZSZ0lgxvlKjYltu0vMM2kUWlsf5Ub/jRmAANYzZBteSnvXPTJ9cPa0Syzfl1f9qRDVVirGxlXSd8plov7dhBrLgJ1V1TNNSf5Jgc+Xwdapa94M370VjUtbh/6aEUCK2gsMQCB1Rvl7Xrd8/Oj0PT97FQCcDxI4JH9STTqt0p9JtLGYKE4x+HwjNBYL5VXjpZWqXDfRn1rZ1fR+Hxqre0Oy9AF22hQmsoHHAA08JlhMuHapSE5rOfKD5eO/+jUGcK5IHvb9KVnQC9kOnO2/M7VD5w/Oh9jtPzrQ2jHZvNm8+0IydvFToZl/pJBwHVPgtYi+Gw8IY7kAmEIpASyAUcCyyW6VTv144czyM0dnoFedXO32Xre0fdMNTU+3qmT7mO0z6ns3qu8nwkyOPBgKgYGZwMxgsnpyISB6zXVvcCn/onTjk9U5ffy7h+dXcBnccDZ/a+7L69mK3StUfVJ8/xO5yz6kLhsnIQYEoFGwKQCNcg21lXaZn2ZNqFVgvmRUTJ0O6cj3W43rn5o+fDTHFXDt2Ye2vPvTdz7Xby7fSVTsYQobzKmYVHC1bjABrAphjSqHqHMaZjDKALAhu/6vPkw8duJ3p345O/t8if8A1y+7NxU+PZS63i3gQA04CNmgnMTChBCDVcFEMEY9KiObogIgSnDqlIvk5TIdeSQ78cEXZmePKa4C2rvm6c6t7/1iSPpHlFZ3Vc2CwIJGaIJcBZJQe0p18ajIGQQKB4rvhdRc2er5auwZ6Y8/8Z0v/fz4Nf2P2+029zYt3pH6S/f3J7p3B98da6ojJgEzIHXz1CfkKoY3hoNAwJUP/jWfj83lGZ6e+cqzS7gGOAAY/CdfvP/R+16fHG29VKg/qJLtMgljgylNAOqbwticOR0pXNl8WwzP5b1sXhc+/LdY5xpBuAJmRtM/+fq2LOl9nJL+x4RoJ6A3sFQs6pSNlwx4Q1T+gp5/9dQiXpufmS/wf+LfoKyozq4MSD0AAAAASUVORK5CYII=")
            /*../Image/FeuilleBullet.png*/;
    }

    .list-level2 li {
        margin-left: 70px;
    }
}
