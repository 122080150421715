@import '../../styles/main.scss';

.textXs {
    font-size: 0.8rem;
}

.textSm {
    font-size: 1rem;
}

.textMd {
    font-size: 1.2rem;
}

.textLg {
    font-size: 1.4rem;
}

.textXl {
    font-size: 1.6rem;
}

@media (min-width: $screen-md-min){
    .textXs {
        font-size: 1.2rem;
    }

    .textSm {
        font-size: 1.4rem;
    }

    .textMd {
        font-size: 1.6rem;
    }

    .textLg {
        font-size: 1.8rem;
    }

    .textXl {
        font-size: 2rem;
    }
}