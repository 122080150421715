@import '../../styles/main.scss';

@font-face {
    font-family: GlyphFont;
    src: url(../../fonts/entypo.eot) format('embedded-opentype'),
        url(../../fonts/entypo.ttf) format('truetype'),
        url(../../fonts/entypo.woff) format('woff'),         
        url(../../fonts/entypo.svg) format('svg');
}

.glyphIcon {
    display: inline-block;    
    font-family: GlyphFont;
    font-size: 80px;
    color: #5f9dcc;    
    text-align: center;     
}

.person {    
    margin: -40px -4px 0 -4px;
}

.cell {    
    margin: -40px -2px 0 -4px;
}

.email {    
    margin: -47px -3px -8px -4px;
}

.hamburgerMenu {    
    margin: -49px -3px -10px -3px;
}

.close {
    margin: -49px -3px -10px -3px;
}

.fontPrimaryColor{
    color: $font-color-primary;
}